.modal {
  &-header {
    color: $white;
    background-color: $secondary;
    // @include border-top-radius($modal-content-inner-border-radius)

    .close {
      color: $white;
      text-shadow: none;
    }
  }

  &-title {
    color: white;
    font-weight: bold;
  }
}
