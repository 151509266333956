@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "variables";
@import "~bootstrap/scss/bootstrap";

@import "components/alerts";
@import "components/badges";
@import "components/breadcrumbs";
@import "components/buttons";
@import "components/cards";
@import "components/chrome";
@import "components/comments";
@import "components/forms";
@import "components/headings";
@import "components/modals";
@import "components/multiselect";
@import "components/notifications";
@import "components/orders";
@import "components/steppers";
@import "components/tables";
@import "components/tabs";
@import "components/sortable";

@import 'rails';
@import 'collapsible_tree';
@import 'material';

// Bootstrap icons use a fixed pixel size for compat with certain SVG editors
// Revert back to relative sizing so we can adjust the icon size using the
// font size. This changed in:
//
// https://github.com/twbs/icons/pull/497
svg { width: 1em; height: 1em }

// Probably needs to go somewhere else but no obvious place right now
.scrollable { overflow-y: auto; overflow-x: hidden }
