.import-sidebar, .order-main { @extend .col-12 }

body.hide-order-sidebar {
  .import-sidebar { display: none }
}

body:not(.hide-order-sidebar) {
  .order-sidebar {
    @extend .col-xl-4;
    @extend .order-xl-2;
  }

  .order-main {
    @extend .col-xl-8;
    @extend .order-xl-1;
  }
}
