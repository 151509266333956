.badge {
  &-circle {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.25em;
    width: 2em;
    height: 2em;
    border-radius: 50rem;
  }

  &-lg {
    font-size: 1rem;
  }

  &-dot {
    display: inline-block;
    width: 8px;
    height: 8px;
    padding: 0;
    border-radius: 50rem;
    line-height: 1;
    min-width: 0;
    pointer-events: auto;
    position: absolute;
    top: auto;
    right: 0;
    transition: .3s cubic-bezier(.25, .8, .5, 1);
  }

  &-outline {
    border: 1px solid currentColor;
    border-width: 1px;
    border-style: solid;
    background-color: transparent;
  }

  @each $color, $value in $theme-colors {
    &-text-#{$color} {
      color: $value;
    }

    &-outline.badge-#{$color} {
      color: $value;
      border-color: $value;
    }
  }
}

a.badge {
  text-decoration: none;
}