.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 20;
  padding: 72px 0 0;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
  width: 100%;
  max-width: 4.5rem;
  display: none;

  @include media-breakpoint-up(sm) {
    display: block;
  }

  .sidebar-open & {
    @include make-col-ready();
    @include make-col(12);
    max-width: 100vw;
    position: fixed;
    padding: 72px 0 0;
    display: block;

    &-sticky { @extend .overflow-auto }
  }

  &-sticky {
    position: relative;
    position: sticky;
    top: 0;
    height: calc(100vh - 72px);
  }

  .nav-item {
    display: flex;
    flex-wrap: wrap;

    &.active {
      > .nav-link {
        border-left-color: $purple;
        background-color: theme-color("secondary-dark");
      }

      > .dropdown-toggle {
        background-color: theme-color("secondary-dark");
      }
    }

    &:hover {
      > .nav-link,
      > .dropdown-toggle {
        background-color: theme-color("secondary-dark");
      }

      > .dropdown-toggle {
        &:hover {
          background-color: rgba(#000, 0.25);
        }
      }
    }

    &.dropdown {
      > .nav-link {
        position: relative;

        &::after {
          content: "";
          position: absolute;
          right: 3px;
          top: calc(50% - 0.5rem);
          background-image: url("data:image/svg+xml,%3Csvg width='1em' height='1em' viewBox='0 0 16 16' class='bi bi-chevron-right' fill='%23ffffff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
          width: 1rem;
          height: 1rem;
          background-repeat: no-repeat;
          background-position: center;
          transition: transform 100ms ease-out;
        }
      }

      &.show {
        > .nav-link {
          &::after {
            .sidebar-open & {
              transform: rotate(90deg);
            }
          }
        }
      }
    }
  }

  .nav-link {
    display: flex;
    flex-grow: 1;
    justify-content: center;
    align-items: center;
    padding: $spacer;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    color: $white;
    font-weight: 300;
    font-size: 1.5rem;

    .sidebar-open & {
      justify-content: start;
    }

    @include media-breakpoint-up(md) {
      .sidebar-open & {
        font-size: 1rem;
      }
    }

    &.active {
      border-color: $purple;
      background-color: theme-color("secondary-dark");
    }

    &:hover {
      background-color: theme-color("secondary-dark");
    }

    svg {
      color: theme-color("secondary-light");
    }
  }

  .nav-label {
    margin-left: calc($spacer / 2);
    display: none;

    .sidebar-open & {
      display: inline-block;
    }
  }

  .dropdown-toggle {
    display: none;
    min-width: 2rem;
    border: 0;
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg width='1em' height='1em' viewBox='0 0 16 16' class='bi bi-chevron-right' fill='white' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
    background-position: center;
    background-repeat: no-repeat;
    user-select: none;
    color: white;

    &::after {
      display: none;
    }

    .sidebar-open & {
      display: inline-block;
    }
  }

  .dropdown-menu {
    width: 100%;
    position: absolute;
    float: none;
    margin: 0;
    padding: 0;
    color: #fff;
    border: none;
    border-radius: 0;
    background-color: $secondary;
    min-width: max-content;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175);

    .sidebar-open & {
      background: none;
      position: static;
      box-shadow: none;
    }

    .nav-link {
      justify-content: start;

      @include media-breakpoint-up(md) {
        font-size: 1.25rem;
      }

      .sidebar-open & {
        padding-left: 2.5rem;

        @include media-breakpoint-up(md) {
          font-size: 1rem;
        }
      }
    }

    .nav-label {
      display: inline-block;
    }
  }

  .show {
    > .dropdown-toggle {
      background-image: url("data:image/svg+xml,%3Csvg width='1em' height='1em' viewBox='0 0 16 16' class='bi bi-chevron-down' fill='white' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
    }
  }
} // END .sidebar

// Breakpoints for opened sidebar at larger screens
.sidebar-open {
  @include media-breakpoint-up(sm) {
    .topbar-brand,
    .sidebar {
      @include make-col(5);
    }
  }

  @include media-breakpoint-up(md) {
    .topbar-brand,
    .sidebar {
      @include make-col(4);
    }

    .topbar-content, .main-content {
      @include make-col(8);
      margin-left: auto;
      padding-left: $spacer;
    }
  }

  @include media-breakpoint-up(lg) {
    .topbar-brand,
    .sidebar {
      @include make-col(3);
    }

    .topbar-content, .main-content {
      @include make-col(9);
    }
  }
} // END .sidebar-open

@include media-breakpoint-up(xl) {
  .import-sidebar {
    position: sticky;
    top: 6rem;
    height: calc(100vh - 20rem);
    overflow-y: auto;
  }
}
