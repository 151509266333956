.btn {
  &-transparent {
    &:hover {
      background-color: rgba(#000, 0.07);
    }
  }

  &-circle {
    border-radius: 50rem;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    line-height: 1;
    padding: 0.25em;
    width: 2em;
    height: 2em;
  }

  &-icon {
    border-radius: 50rem;
    display: inline-flex;
    align-items: center;
    font-size: $font-size-sm;

    span {
      border-radius: 50rem;
      font-size: $font-size-base;
      padding: 0.25em;
      line-height: 1;
      width: 1.5em;
      height: 1.5em;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: -($spacer * 0.25);
      margin-right: $spacer;
    }

    &-right {
      flex-direction: row-reverse;

      span {
        margin-right: -($spacer * 0.25);
        margin-left: $spacer;
      }
    }

    @each $color,
    $value in $theme-colors {
      &-#{$color} {
        span {
          background-color: $value;
        }
      }
    }
  }
}

// Buttons that are progressively enhanced hide until Vue has a chance to
// convert them to full Bootstrap buttons.
a, button {
  &[data-icon], &[data-priorty], &[date-purpose], &[data-size], &[data-outline], &[data-link], &[data-confirm], &[data-circle] {
    visibility: hidden;
  }
}
