@import "topbar";
@import "sidebar";
@import "footer";

body {
  margin-bottom: ($spacer * 2);
}

.collapse {
  visibility: hidden;

  &.show {
    visibility: visible;
    display: block;
  }

  &-horizontal {
    &.collapse:not(.show) {
      width: 0;
    }

    &.show {
      width: 100%;
    }

    &.collapsing {
      position: relative;
      width: 0;
      height: auto;
      overflow: hidden;
      transition: width 0.35s ease;
    }
  }
} // END .collapse

.collapsing {
  height: 0;
  overflow: hidden;

  &.width {
    width: 0;
    height: auto;
    transition-property: width, visibility;
  }
} // END .collapsing

.main-content {
  @include make-col-ready();
  @include make-col(12);
  margin-left: 0;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;

  @include media-breakpoint-up(sm) {
    padding-left: calc( #{$spacer} + 4.5rem );
  }
} // END .main-content
