.stepper {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: space-between;

  &-step {
    display: flex;
    position: relative;
    padding: $spacer;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex-basis: 12.5rem;

    &-icon {
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: 1.25rem;
      width: 2rem;
      height: 2rem;
      border: 2px solid $gray-600;
      background-color: $stepper-unstarted-icon-background;
      border-radius: 50rem;
      background-repeat: no-repeat;
      background-position: center;
      margin: 0 0.5rem;
      transition-property: background;
      transition-duration: $stepper-icon-transition-duration;
      transition-timing-function: $stepper-transition-easing;
    }

    &-label {
      color: $secondary;
      margin: 0.5rem 0;
    }

    &.incomplete {
      .stepper-step-icon {
        background-color: $stepper-incomplete-icon-background;
        background-image: url("data:image/svg+xml,%3Csvg width='1em' height='1em' viewBox='0 0 16 16' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' d='M2 1.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-1v1a4.5 4.5 0 0 1-2.557 4.06c-.29.139-.443.377-.443.59v.7c0 .213.154.451.443.59A4.5 4.5 0 0 1 12.5 13v1h1a.5.5 0 0 1 0 1h-11a.5.5 0 1 1 0-1h1v-1a4.5 4.5 0 0 1 2.557-4.06c.29-.139.443-.377.443-.59v-.7c0-.213-.154-.451-.443-.59A4.5 4.5 0 0 1 3.5 3V2h-1a.5.5 0 0 1-.5-.5zm2.5.5v1a3.5 3.5 0 0 0 1.989 3.158c.533.256 1.011.791 1.011 1.491v.702c0 .7-.478 1.235-1.011 1.491A3.5 3.5 0 0 0 4.5 13v1h7v-1a3.5 3.5 0 0 0-1.989-3.158C8.978 9.586 8.5 9.052 8.5 8.351v-.702c0-.7.478-1.235 1.011-1.491A3.5 3.5 0 0 0 11.5 3V2h-7z'/%3E%3C/svg%3E");
      }
    }

    &.complete {
      .stepper-step-icon {
        background-color: $stepper-complete-icon-background;
        background-image: url("data:image/svg+xml,%3Csvg width='1em' height='1em' viewBox='0 0 16 16' fill='%23fff' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' d='M13.854 3.646a.5.5 0 0 1 0 .708l-7 7a.5.5 0 0 1-.708 0l-3.5-3.5a.5.5 0 1 1 .708-.708L6.5 10.293l6.646-6.647a.5.5 0 0 1 .708 0z'/%3E%3C/svg%3E");
      }

      + hr {
        &::after {
          width: 100%;
        }
      }
    }
  }

  hr {
    position: relative;
    overflow: hidden;
    align-self: flex-start;
    flex: 1 1 0px;
    height: 0.5rem;
    margin: 1.75rem ($spacer * -4) 0;
    border: none;
    background-color: $stepper-line-background;
    border-radius: 50rem;
    display: block;
    max-width: 100%;

    &::after {
      content: '';
      display: block;
      top: 0;
      bottom: 0;
      left: 0;
      height: 100%;
      width: 0;
      border-radius: 50rem;
      background: $stepper-line-background-filled;
      transition-property: width;
      transition-duration: $stepper-line-transition-duration;
      transition-timing-function: $stepper-transition-easing;
    }

    &:last-child {
      display: none;
    }
  }
}

[class*="stepper-showlabels"] {
  .stepper-step-label {
    display: none;
  }
}

// Breakpoints to show step labels
// Example: .steeper-showlabels-md will show labels at medium and up
@each $breakpoint,
$container-max-width in $container-max-widths {
  .stepper-showlabels-#{$breakpoint} .stepper-step-label {
    @include media-breakpoint-up($breakpoint) {
      display: inline-block;
    }
  }
}

// Breakpoints to stack the stepper
// Example: .steeper-stack-sm will stack the steps at small and below
@each $breakpoint,
$container-min-widths in $grid-breakpoints {
  @include media-breakpoint-down($breakpoint) {
    .stepper-stack-#{$breakpoint} {
      flex-direction: column;
      justify-content: flex-start;
      align-items: stretch;

      .stepper-step {
        flex-direction: row;
        flex-basis: 0;
        align-items: center;
        justify-content: flex-start;
      }

      hr {
        margin: -1rem 2.25rem;
        width: 0.5rem;
        height: 2rem;
        flex: auto;
      }

      &[class*="stepper-showlabels"] {
        .stepper-step-label {
          display: inline-block;
        }
      }
    }
  }
}