h1 {
  color: $secondary;

  &:last-child {
    margin-bottom: 0;
  }
}

.tabbed-heading {
  color: $white;
  background-color: $secondary;
  font-size: $font-size-sm;
  font-weight: $font-weight-bold;
  padding: ($spacer * 0.75) $spacer;
  border-left: 0.25rem solid $orange;

  &-rounded {
    border-top-right-radius: 50rem;
    border-bottom-right-radius: 50rem;
  }

  .card & {
    margin-left: -$card-spacer-x;
    margin-right: $spacer;
    margin-bottom: 0;
    min-width: 50%;
  }
}

h5 {
  font-weight: $font-weight-normal;
  color: $gray-500;
}