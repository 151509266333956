:not(.card-deck, .col) > .card {
  flex-basis: 100%;
  margin-bottom: $spacer * 2;

  &:not(:first-child) {
    margin-top: $spacer * 2;
  }
}

.card-header {
  h2, h3 { margin: 0 }
  h2 { font-size: $font-size-lg }
  h3 { font-size: $font-size-sm }

  .row {
    align-items: center;
  }

  [data-toggle] {
    color: #fff;

    svg {
      transition: transform 150ms ease-out;
      will-change: transform;
    }
  }

  .open > & {
    [data-toggle] {
      svg {
        transform: rotateX(180deg);
      }
    }
  }
}

.card-footer {
  background-color: $card-footer-bg;
  color: $card-footer-color;
}

.card-body {
  display: flex;
  flex-direction: column;

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $spacer;
  }

  dl {
    @extend .row;
    align-items: baseline;
  }

  dl > div {
    @extend .col-12;
    @extend .row;
    @extend .p-0;
    @extend .m-0;
  }

  dt {
    @extend .col-5;
    margin-bottom: 0.5em;
  }

  dd {
    @extend .col-7;
  }

  .btn:last-child {
    margin-top: auto;
  }
}

.card-details {
  &-group {
    margin-bottom: $spacer;
  }

  &-header {
    dd {
      font-weight: 700;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  :not(&-header) {
    & > dt, & > dd {
      @extend .text-truncate;
    }
  }
}
