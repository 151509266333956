.comments .comment {
  padding: $spacer;
  border: 1px solid $gray-500;

  .row {
    margin-left: -0.5rem;
    margin-right: -0.5rem;

    > [class*="col"] {
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }
  }

  &-avatar {
    display: block;
    width: 3rem;
    height: 3rem;
    border-radius: 50rem;
  }

  &-author,
  &-date {
    margin: 0;
    font-weight: 500;
  }

  &-body {
    margin-top: $spacer;

    > *:last-child {
      margin-bottom: 0;
    }
  }
}
