// Taken directly from
// https://github.com/Hemasystems/bloodhub_core/blob/master/app/assets/stylesheets/application/tables.css.scss

@mixin stacked-table
{
  // Force table to not be like tables anymore
  table, &table, thead, tbody, tfoot, th, td, tr,
  // Also override some stuff explicitly set elsewhere
  tr.collapse.in, tbody.collapse.in, thead.collapse.in, tfoot.collapse.in
  {
    display: block;
  }

  // Hide table headers (but not display: none;, for accessibility)
  thead tr
  {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  tr { border: 1px solid var(--bs-table-bg); }

  .hidden-stacked
  {
    display: none !important;
  }
  .visible-stacked,
  .visible-stacked-block
  {
    display: block !important;
  }
  table.visible-stacked
  {
    display: table !important;
  }
  tr.visible-stacked
  {
    display: table-row !important;
  }
  th.visible-stacked,
  td.visible-stacked
  {
    display: table-cell !important;
  }
  .visible-stacked-inline
  {
    display: inline !important;
  }
  .visible-stacked-inline-block
  {
    display: inline-block !important;
  }

  tbody, tfoot
  {
    // Only setup the table heading if the cell has a data-th attribute
    td[data-th], th[data-th]
    {
      // Behave  like a "row"
      position: relative;
      padding-left: 50% !important;
      text-align: left !important;

      &:before
      {
        // Now like a table header
        position: absolute;

        // Top/left values mimic padding
        top: 8px;
        left: 8px;
        width: 45%;
        padding-right: 10px;
        // white-space: nowrap; // We probably want to be able to read the label.

        // Label the data
        content: attr(data-th);
        font-weight: bold;
        text-align: left;
        // text-overflow: ellipsis; // This does nothing apparently.
      }

      // We need to ensure that these cell classnames still work when the table
      // is stacked.
      &.text-overflow
      {
        max-width: none;
        overflow: visible;
        text-overflow: clip;
        white-space: normal;
        display: block;
      }
      &.no-wrap
      {
        width: auto;
        white-space: normal;
      }
      &.text-nowrap
      {
        white-space: normal;
      }
    }
  }

  table.table-vcenter, &table.table-vcenter
  {
    tbody, tfoot
    {
      td[data-th], th[data-th]
      {
        &:before
        {
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
}

@mixin sortable-cell($content, $color)
{
  &:after
  {
    vertical-align: middle;
    margin-left: 3px;
    font-family: FontAwesome;
    content: $content;
    color: $color;
  }
}

tr.sortable > th,
tr.sortable > td,
th.sortable,
td.sortable
{
  cursor: pointer;

  @include sortable-cell('\f0dc', '#afb9c3');

  &.ascending
  {
    @include sortable-cell('\f0de', inherit);
  }

  &.descending
  {
    @include sortable-cell('\f0dd', inherit);
  }
}

.hidden-stacked,
.hidden-stacked-block
{
  display: block !important;
}
table.hidden-stacked
{
  display: table !important;
}
tr.hidden-stacked
{
  display: table-row !important;
}
th.hidden-stacked,
td.hidden-stacked
{
  display: table-cell !important;
}
.hidden-stacked-inline
{
  display: inline !important;
}
.hidden-stacked-inline-block
{
  display: inline-block !important;
}
.visible-stacked
{
  display: none !important;
}


// XS (< 768px)
@media only screen and (max-width: 767px)
{
  .table-stacked, .table-stacked-xs
  {
    @include stacked-table;
  }
}


// SM (>= 768px)
@media only screen and (max-width: 991px)
{
  .table-stacked-sm
  {
    @include stacked-table;
  }
}


// MD (>= 992px)
@media only screen and (max-width: 1199px)
{
  .table-stacked-md
  {
    @include stacked-table;
  }
}

// LG (>= 1200px)
@media only screen and (min-width: 1200px)
{
  .table-stacked-lg
  {
    @include stacked-table;
  }
}
