.form {
  &-label {
    &-lg {
      font-size: 1.5rem;
      font-weight: 500;
    }
  }
}

.form-group {
  label { font-weight: $font-weight-bold }
  .custom-control label { font-weight: $font-weight-normal }
  .persisted-value { float: right; top: 0.25rem }
}

.form-control {
  &-rounded {
    border-radius: 50rem;
  }

  &-width-auto {
    width: auto;

    @include media-breakpoint-up(sm) {
      min-width: 20rem;
    }
  }
}

.custom-select {
  &-shrink {
    max-width: max-content;
  }
}

.custom-switch { padding-top: 2.25rem }
.no-pad .custom-switch { padding-top: 0 }

.form-control-static { @extend .py-2; }
.form-inline .form-control-static { @extend .d-inline-block, .ml-2, .mr-3; }

abbr[title=required] { text-decoration: none }

.report-template-actions { right: 0; top: -1rem }
