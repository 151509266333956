.site-footer {
  position: fixed;
  z-index: 30;
  bottom: 0;
  left: 0;
  right: 0;
  padding: calc($spacer / 2) $spacer;
  line-height: 1;
  background: $white;
  border-top: 1px solid $gray-200;
} // END .site-footer

.site-version {
  color: $secondary;
  text-decoration: underline;
  cursor: pointer;
} // END .site-version

.copyright-tooltip {
  padding: 0 0.75rem;

  &.show {
    opacity: 1;
  }

  .tooltip-inner {
    @extend .shadow;
    position: relative;
    max-width: 300px;
    text-align: left;
    background: #fff;
    color: $black;
    border: 1px solid $gray-300;
    border-bottom-right-radius: 0;

    &::before, &::after {
      content: "";
      pointer-events: none;
      bottom: 1px;
      border: solid transparent;
      height: 0;
      width: 0;
      position: absolute;
    }

    &::before {
      // border-color: transparent;
      // border-left-color: $gray-300;
      border-width: 10px;
      margin-top: -10px;
      transform: translateY(2px);
    }

    &::after {
      // border-color: rgba($white, 0);
      // border-left-color: $white;
      border-width: 8px;
      margin-top: -8px;
    }

    p:last-child {
      margin-bottom: 0;
    }
  }

  &.bs-tooltip-left {
    .tooltip-inner {
      &::before, &::after {
        left: 100%;
      }

      &::before {
        border-left-color: $gray-300;
      }

      &::after {
        border-left-color: $white;
      }
    }
  }

  &.bs-tooltip-right {
    .tooltip-inner {
      &::before, &::after {
        right: 100%;
      }

      &::before {
        border-right-color: $gray-300;
      }

      &::after {
        border-right-color: $white;
      }
    }
  }

  .arrow {
    display: none;
  }
} // END .copyright-tooltip
