// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Color system

$white: #fff !default;
$gray-100: #f0f0f7;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #bcbccb;
$gray-600: #989a9b;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$black: #373a3c;

// fusv-disable
$grays: ("100": $gray-100,
  "200": $gray-200,
  "300": $gray-300,
  "400": $gray-400,
  "500": $gray-500,
  "600": $gray-600,
  "700": $gray-700,
  "800": $gray-800,
  "900": $gray-900) !default;
// fusv-enable

$blue: #3b86ff;
$blue-dark: #43425c;
$blue-darker: #3c3b53;
$blue-light: #a5a4bf;
$indigo: #6610f2 !default;
$purple: #a3a0fb;
$pink: #d63384 !default;
$red: #ff010b;
$orange: #fe8824;
$yellow: #ffb100;
$green: #21ba45;
$teal: #20c997 !default;
$cyan: #0dcaf0 !default;

// scss-docs-start colors-map
$colors: ("blue": $blue,
  "blue-dark": $blue-dark,
  "blue-darker": $blue-darker,
  "blue-light": $blue-light,
  "indigo": $indigo,
  "purple": $purple,
  "pink": $pink,
  "red": $red,
  "orange": $orange,
  "yellow": $yellow,
  "green": $green,
  "teal": $teal,
  "cyan": $cyan,
  "white": $white,
  "gray": $gray-600,
  "gray-dark": $gray-800);
// scss-docs-end colors-map

$primary: $blue !default;
$secondary: $blue-dark;
$secondary-dark: $blue-darker;
$secondary-light: $blue-light;
$success: $green !default;
$info: $cyan !default;
$warning: $yellow !default;
$danger: $red !default;
$light: $gray-100 !default;
$dark: $black;
$accent: $purple;

// scss-docs-start theme-colors-map
$theme-colors: ("primary": $primary,
  "secondary": $secondary,
  "secondary-dark": $secondary-dark,
  "secondary-light": $secondary-light,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "light": $light,
  "dark": $dark,
  "accent": $accent);
// scss-docs-end theme-colors-map


// Body
//
// Settings for the `<body>` element.

$body-bg: $gray-100;
$body-color: $black;
$body-text-align: null !default;


// Links
//
// Style anchor elements.

$link-color: $gray-700;
$link-decoration: underline;
$link-hover-color: darken($link-color, 15%) !default;
$link-hover-decoration: underline !default;
// Darken percentage for links with `.text-*` class (e.g. `.text-success`)
$emphasized-link-hover-darken-percentage: 15% !default;


// Typography
//
// Font, line-height, and color for body text, headings, and more.

// stylelint-disable value-keyword-case
$font-family-sans-serif:     "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-base: $font-family-sans-serif;

$h1-font-size:    $font-size-base * 1.75;
$h2-font-size:    $font-size-base * 1.5;
$h3-font-size:    $font-size-base * 1.25;
$h4-font-size:    $font-size-base * 1.1;
$h5-font-size:    $font-size-base * 1;
$h6-font-size:    $font-size-base * 0.875;

$headings-font-weight: 700;


// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

$table-accent-bg: $gray-200;

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-gutter-width:           $spacer * 2;


// Components
//
// Define common padding and border radius sizes and more.

$border-radius: .25rem;
$border-radius-sm: .1rem;
$border-radius-lg: 0.75rem;

// Cards

$card-border-radius: $border-radius-lg;
$card-inner-border-radius: 	subtract($border-radius-lg, $card-border-width);
$card-cap-bg: $secondary;
$card-cap-color: $white;
$card-footer-bg: $light;
$card-footer-color: $black;


// Pagination

$pagination-active-bg: $secondary;


// Navbar

$navbar-brand-font-size: $font-size-base;

$navbar-dark-toggler-border-color: transparent;
$navbar-dark-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='#{$white}' stroke-linecap='square' stroke-miterlimit='10' stroke-width='4' d='M4 7h22M4 15h22M4 23h22'/></svg>");


// Breadcrumbs

$breadcrumb-bg: transparent;
$breadcrumb-padding-x: 0;
$breadcrumb-divider: url("data:image/svg+xml,%3Csvg width='1em' height='1em' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='#{$black}' d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");


// Toasts

$toast-font-size: 1.25rem;
$toast-background-color: $white;
$toast-border-width: 0;
$toast-border-radius: 0;


// Badges

$badge-font-weight: $font-weight-normal;


// Modals

$modal-content-border-radius: 12px;
$modal-content-inner-border-radius: subtract($modal-content-border-radius, $modal-content-border-width);

$modal-backdrop-opacity: .3;


// Stepper - Custom component

$stepper-unstarted-icon-background: $gray-500;
$stepper-incomplete-icon-background: $warning;
$stepper-complete-icon-background: $success;
$stepper-line-background: $gray-500;
$stepper-line-background-filled: $secondary;

$stepper-transition-easing: ease-out;
$stepper-line-transition-duration: 350ms;
$stepper-icon-transition-duration: 200ms;

// Forms

// Similer to the server-side feedback classes[1] only provides the
// "warning" styling instead of the "danger" or "success" stylings.
//
// 1. https://getbootstrap.com/docs/4.1/components/forms/#server-side
$form-validation-states: map-merge(
  (
    "warning": (
      "color": $warning,
      "icon": '<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-cone" viewBox="0 0 16 16"><path d="M7.03 1.88c.252-1.01 1.688-1.01 1.94 0l2.905 11.62H14a.5.5 0 0 1 0 1H2a.5.5 0 0 1 0-1h2.125L7.03 1.88z"/></svg>',
    ),
  ),
  $form-validation-states
);
