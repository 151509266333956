.alert {
  &-environment {
    border-radius: 0;

    .main-content > & {
      margin-top: -1.5rem;
      margin-left: -15px;
      margin-right: -15px;
    }
  }
}

.toast-footer {
  @extend .modal-footer;
}
