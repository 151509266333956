.toast {
  pointer-events: all;
  max-width: 100%;
  min-width: 20rem;
  flex-basis: auto;

  &-body {
    display: flex;
    align-items: center;
    font-weight: 300;

    button {
      margin-left: auto;
      color: $gray-600;
      float: none;
    }
  }

  &-message {
    margin: 0 $spacer;
  }

  &-positioning {
    position: fixed;
    left: 0;
    right: 0;
    top: 4.5rem;
    min-height: 1px;
    z-index: 9999;
    pointer-events: none;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}
