.topbar {
  min-height: 72px;

  &-brand {
    padding: 1rem;
    padding-left: 1.5rem;
    display: flex;
    align-items: center;
    background-color: theme-color("secondary-dark");
    width: 100%;
    max-width: 4.5rem;
    padding-left: 0;
    padding-right: 0;
    justify-content: center;

    .sidebar-open & {
      @include make-col-ready();
      @include make-col(12);
      max-width: 100vw;
      padding: 1rem;
      justify-content: space-between;
      position: fixed;
      z-index: 20;
    }
  }

  .navbar-brand {
    font-weight: $font-weight-bold;
    text-decoration: none;
    letter-spacing: 0.1em;

    :not(.sidebar-open) & {
      display: none;
    }

    .sidebar-open & {
      display: block;
    }
  }

  .brand-container {
    display: inline-flex;
    align-items: center;
    padding: 1rem;
    justify-content: space-between;
    color: $blue-dark;
    max-width: 100vw;

    .brand-label {
      font-weight: $font-weight-bold;
      text-decoration: none;
      letter-spacing: 0.1em;
    }
  }

  &-item {
    display: flex;
    align-items: center;
    position: relative;

    .btn {
      line-height: 1;
      color: $gray-500;
    }
  }

  &-separator {
    margin: auto $spacer;
    height: $spacer * 2;
    width: 1px;
    border: 0;
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
  }

  &-account {
    align-self: stretch;
    margin-right: $spacer;

    summary {
      height: 100%;
      list-style: none;
      display: flex;
      align-items: center;
      color: theme-color("dark");
      outline: none;

      img {
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 50rem;
      }

      svg {
        margin: 0 ($spacer * 0.5);
        color: $gray-500;
      }

      &:before,
      &::marker {
        display: none;
      }
    }

    &[open] {
      nav {
        position: absolute;
        top: 100%;
        right: 0;
        left: auto;
        min-width: 250px;
        background: white;
        z-index: 1;
        box-shadow: $box-shadow-lg;
      }
    }

    &-name {
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
  }

  &-search {
    &-form {
      position: absolute;
      top: 100%;
      right: 0;
      left: auto;
      background: $white;
      @extend .shadow-lg;

      form {
        padding: $spacer;
        display: block;
      }
    }
  }

  &-notifications {
    .badge-dot {
      top: 3px;
      right: 8px;
      border: 2px solid $white;
      box-sizing: content-box;
    }
  }

  &-impersonate, &-workstation {
    align-self: stretch;

    .btn {
      height: 100%;
      display: flex;
      align-items: center;

      svg {
        margin-right: calc($spacer / 2);
      }
    }

    &-search {
      position: absolute;
      top: 100%;
      right: 0;
      left: auto;
      background: $white;
      width: 100%;
      z-index: 1;
      @extend .shadow-lg;

      @include media-breakpoint-up(md) {
        max-width: 50vw;
      }
    }

    &-list {
      max-height: 40vh;
      overflow-x: hidden;
      overflow-y: auto;

      .row {
        margin-top: $spacer;
        margin-bottom: $spacer;
      }
    }
  }
} // END .topbar
