.table {
  .min-width { width: 1%; white-space: nowrap }
  .odd { background-color: $table-accent-bg }

  &-vcenter {
    th, td { vertical-align: middle }
  }

  &-natural-width {
    width: unset !important;
    th, td { @extend .pr-3 }
  }

  .form-group { margin-bottom: 0 }
}

// Renders something that looks like a table but instead relies on Bootstrap's
// grid system for layout. This gives markup that is more flexibility for
// deviations from a standard table.
.grid-table {
  margin-bottom: $spacer;
  .alert { margin-bottom: 0; border-radius: 0 }
  .head { font-weight: bold }
  .row { margin-left: 0; margin-right: 0 }
  .row > * {
    padding-top: $table-cell-padding-sm; padding-bottom: $table-cell-padding-sm;
    padding-left: $table-cell-padding-sm;
    padding-right:$table-cell-padding-sm;
    @extend .my-auto;
  }
  .body .row {
    &.odd { background-color: $table-accent-bg }
  }
  .active { background-color: $table-active-bg }
}

.card .card-body {
  & > .card-text .table, & > .grid-table {
    width: calc(100% + 2.5rem); margin-left: -1.25rem; margin-right: -1.25rem;
    &:last-child { margin-bottom: -1.25rem }

    th:first-of-type, td:first-of-type, .row > :first-of-type { padding-left: 1.25rem }
    th:last-of-type, td:last-of-type, .row > :last-of-type { padding-right: 1.25rem }
  }
}
